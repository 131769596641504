import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCountryLookup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Country/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiRegionsAutocompleteService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Region/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiRegionsService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Region/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiDistrictLookup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/District/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiTerritorialCommunityLookup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/TerritorialCommunity/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiSettlementLookup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Settlement/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiSettlementDistrictLookup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/SettlementDistrict/Lookup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationLocationTypes extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationLocationTypes');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiClientCategoryAppealByCode extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Dictionaries/byCode/ClientCategoryAppeal');
  }
}

